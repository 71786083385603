<template>
  <b-container fluid class="p-0">
    <b-row class="new-row">
      <b-col cols="4" class="col-right" id="col-right">
        <div class="register-step-left-side">
          <div class="auth-left-header zoom">
            <a href="#">
              <img src="/img/logo-general.svg" width="160" height="18" alt />
            </a>
          </div>
          <div class="auth-left-top-img zoom">
            <div class="auth-left-top-img-content">
              <img src="/img/login-bg-1.png" alt="" />
            </div>
          </div>
          <div class="auth-left-bottom-left-img zoom">
            <div class="auth-left-bottom-left-img-content position-relative">
              <img src="/img/login-bg-2.png" alt="" />
            </div>
          </div>
          <div class="auth-left-bottom-right-img zoom">
            <div class="auth-left-bottom-left-img-content position-relative">
              <img src="/img/login-bg-3.png" alt="" />
            </div>
          </div>
          <div class="auth-left-center ">
            <!--  style="width: 600px"-->
            <h3
              class="auth-title text-white text-center zoom"
              v-if="changingSteps == 1"
            >
              {{$t("auth.step1.sl-title")}}
            </h3>
            <h3
              class="auth-title text-white text-center"
              v-else-if="changingSteps == 2"
            >
               {{$t("auth.step2.sl-title")}}
            </h3>

            <h3
              class="auth-title text-white text-center"
              v-else-if="changingSteps == 3"
            >
              {{$t("auth.step3.sl-title")}}
            </h3>

            <h3
              class="auth-title text-white text-center"
              v-else-if="changingSteps == 4"
            >
                {{$t("auth.step4.sl-title")}}
            </h3>

            <h3 class="auth-title text-white text-center" v-else>
               {{$t("auth.step1.sl-title")}}
            </h3>

            <p
              class="auth-left-center-desc text-center"
              v-if="changingSteps == 1"
            >
              {{$t("auth.step1.sl-p")}}
            </p>

            <p
              class="auth-left-center-desc text-center"
              v-else-if="changingSteps == 2"
            >
              {{$t("auth.step2.sl-p")}}
            </p>

            <p
              class="auth-left-center-desc text-center"
              v-else-if="changingSteps === 3"
            >
             {{$t("auth.step3.sl-p")}}
            </p>

            <p
              class="auth-left-center-desc text-center"
              v-else-if="changingSteps === 4"
            >
              {{$t("auth.step4.sl-p")}}
            </p>

            <p class="auth-left-center-desc text-center" v-else>
              {{$t("auth.step1.sl-p")}}
            </p>
          </div>
        </div>
      </b-col>

      <b-col cols="12" class="col-left" id="col-left">
        <!--jimir3x-->
        <div
          class="register-step-right-side register-step-right-side__extra register-step-right-side__extra--plus"
          style="background: #ffffff"
        >
          <!--jimir3x-->
          <div v-if="isHaveEmail">
            <div class="topbar px-4 py-2">
              <b-row class="new-row">
                <b-col cols="6">
                  <img src="/img/bludots.svg " alt />
                </b-col>
                <b-col cols="6" class="text-right">
                  <div class="item">
                    <a href="#" @click.prevent="cancelStep">
                      <span class="icon">
                        <v-icon :icon="['fac', 'close']" />
                      </span>
                    </a>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="register-step-header d-flex align-items-center zoom">
              <!-- <div class="left">
              <div class="logo">
                <a href="#">
                  <img src="/img/logo.svg" alt />
                </a>
              </div>
            </div> -->
              <div class="middle d-flex">
                <div
                  class="item"
                  :class="getStep >= 1 ? 'completed no-events' : ''"
                >
                  <router-link
                    :to="'/register-steps/step-1'"
                    href="#"
                    class="d-flex align-items-center"
                  >
                    <div class="item-left">
                      <div class="item-left-icon">
                        <img
                          v-if="getStep >= 1"
                          src="/img/step-done.svg"
                          class="step-icons"
                          alt=""
                        />
                        <img
                          v-else
                          src="/img/step1.svg"
                          class="step-icons"
                          alt=""
                        />

                        <!-- <v-icon
                          :icon="['fac', getStep >= 1 ? 'checked' : 'folder']"
                        ></v-icon> -->
                      </div>
                    </div>
                    <div class="item-right">
                      <p>{{$t("auth.step-titles.title-1")}}</p>
                      <span>{{$t("auth.step-titles.title-p1")}}</span>
                    </div>
                  </router-link>
                </div>
                <div
                  class="item"
                  :class="
                    getStep === -1
                      ? 'no-events'
                      : getStep >= 2
                      ? 'completed no-events'
                      : ''
                  "
                >
                  <router-link
                    :to="'/register-steps/step-2'"
                    class="d-flex align-items-center"
                  >
                    <div class="item-left">
                      <div class="item-left-icon">
                        <img
                          v-if="getStep == 2 && changingSteps === 2"
                          src="/img/step2-blue.svg"
                          class="step-icons"
                          alt=""
                        />
                        <img
                          v-else-if="getStep > 2"
                          src="/img/step-done.svg"
                          class="step-icons"
                          alt=""
                        />
                        <img
                          v-else
                          src="/img/step2.svg"
                          class="step-icons"
                          alt=""
                        />
                        <!-- <v-icon
                          :icon="['fac', getStep >= 2 ? 'checked' : 'role']"
                        ></v-icon> -->
                      </div>
                    </div>
                    <div class="item-right">
                      <p>{{$t("auth.step-titles.title-2")}}</p>
                      <span>{{$t("auth.step-titles.title-p2")}}</span>
                    </div>
                  </router-link>
                </div>
                <div
                  class="item"
                  :class="
                    getStep < 3
                      ? 'no-events'
                      : getStep >= 3
                      ? 'completed no-events'
                      : ''
                  "
                >
                  <router-link
                    :to="'/register-steps/step-3'"
                    href="#"
                    class="d-flex align-items-center"
                  >
                    <div class="item-left">
                      <div class="item-left-icon">
                        <img
                          v-if="getStep == 3 && changingSteps === 3"
                          src="/img/step3-blue.svg"
                          class="step-icons"
                          alt=""
                        />
                        <img
                          v-else-if="getStep > 2 && getStep <= 4"
                          src="/img/step-done.svg"
                          class="step-icons"
                          alt=""
                        />
                        <img
                          v-else
                          src="/img/step3.svg"
                          class="step-icons"
                          alt=""
                        />
                        <!--<v-icon
                          :icon="['fac', getStep > 3 ? 'checked' : 'shopping']"
                        ></v-icon> -->
                      </div>
                    </div>
                    <div class="item-right">
                      <p>{{$t("auth.step-titles.title-3")}}</p>
                      <span>{{$t("auth.step-titles.title-p3")}}</span>
                    </div>
                  </router-link>
                </div>
                <div class="item" :class="getStep <= 4 ? 'no-events' : ''">
                  <a href="#" class="d-flex align-items-center">
                    <div class="item-left">
                      <div class="item-left-icon">
                        <img
                          v-if="getStep <= 4 && changingSteps === 4"
                          src="/img/step4-blue.svg"
                          class="step-icons"
                          alt=""
                        />

                        <img
                          v-else
                          src="/img/step4.svg"
                          class="step-icons"
                          alt=""
                        />
                        <!--<v-icon :icon="['fac', 'user']"></v-icon> -->
                      </div>
                    </div>
                    <div class="item-right">
                      <p>{{$t("auth.step-titles.title-4")}}</p>
                      <span>{{$t("auth.step-titles.title-p4")}}</span>
                    </div>
                  </a>
                </div>
                <div class="cancel-button">
                  <a href="#" @click.prevent="cancelStep">
                    <span class="icon">
                      <v-icon :icon="['fac', 'close']" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="step-header">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-6">
                  <h3 class="step-header-title text-center">
                    General Information
                  </h3>
                  <p class="step-header-sub-title text-center">
                    For Connectter to match you better with right products and
                    the right people, please fill in the information below as
                    accurately as possible.
                  </p>
                </div>
              </div>
            </div>
          </div> -->
            <transition name="router-anim">
              <router-view />
            </transition>
            <b-modal
              :header-bg-variant="'dark'"
              :header-text-variant="'light'"
              id="modal-exit"
              centered
              :title="$t('auth.general.cancel-title')"
            >
              <p class="my-4">
                {{$t("auth.general.leave")}}
              </p>
              <template v-slot:modal-footer>
                <div
                  class="w-100 d-flex align-items-center justify-content-between"
                >
                  <!--<a
                    @click.prevent="
                      $root.$emit('bv::hide::modal', 'modal-exit')
                    "
                    href="#"
                    class="text-primary"
                  >
                    Go back to registration
                  </a> -->
                  <a
                    @click.prevent="backRegister"
                    href="#"
                    class="text-primary"
                  >
                    {{$t("auth.general.go-back")}}
                  </a>
                  <!--<b-button
                    variant="dark"
                    size="xs"
                    class="float-right"
                    @click.prevent="
                      $root.$emit('bv::hide::modal', 'modal-exit')
                    "
                  >
                    Go back to registration
                  </b-button> -->
                  <b-button
                    variant="danger"
                    size="xs"
                    class="float-right"
                    @click="exitStep"
                  >
                    {{$t("auth.general.cancel")}}
                  </b-button>
                </div>
              </template>
            </b-modal>

            <sweet-modal
              id="cancelModal"
              ref="modalCancel"
              :title="$t('auth.general.cancel-title')"
              class="zoom modal-x"
            >
              <p class="my-4">
                 {{$t("auth.general.leave")}}
              </p>

              <sweet-button
                slot="button"
                class="pt-3"
                style="text-align: left !important"
              >
                <a @click="backRegister" href="#" class="text-primary">
                   {{$t("auth.general.go-back")}}
                </a>
              </sweet-button>

              <sweet-button slot="button">
                <b-button
                  style="margin-top: -23px"
                  variant="danger"
                  size="xs"
                  class="float-right"
                  @click="exitStep"
                >
                   {{$t("auth.general.cancel")}}
                </b-button>
              </sweet-button>
            </sweet-modal>
          </div>

          <div v-else style="background: #ffffff; height: 100vh"></div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      isHaveEmail: false,
      changingSteps: 1,
    };
  },
  computed: {
    ...mapGetters({
      getEmail: 'general/getEmail',
      getStep: 'general/getCompleteStep',
    }),
  },
  watch: {
    '$route.path'(value) {
        if (value.indexOf('step-1') > -1) {
            this.changingSteps = 1;
        } else if (value.indexOf('step-2') > -1) {
            this.changingSteps = 2;
        } else if (value.indexOf('step-3') > -1) {
            this.changingSteps = 3;
        } else {
            this.changingSteps = 4;
        }
    }
  },
  methods: {
    exitStep() {
      location.reload();
    },
    cancelStep() {
      //return this.$root.$emit("bv::show::modal", "modal-exit");
      this.$refs.modalCancel.open();
    },
    backRegister() {
      this.$refs.modalCancel.close();
    },
  },
  async created() {
    if (!this.getEmail) {
      return this.$router.push('/auth/login');
    }
    this.isHaveEmail = true;
    if (this.getStep === 2) {
      return this.$router.push('/register-steps/step-2');
    }
  },
};
</script>
